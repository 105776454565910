import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Template} from "./type";
import {map, shareReplay, tap} from "rxjs/operators";
import {GO_TEMPLATE} from "./data/go";
import {JAVA_TEMPLATE} from "./data/java";
import {JS_TEMPLATE} from "./data/js";
import {TS_TEMPLATE} from "./data/ts";
import {KT_TEMPLATE} from "./data/kt";
import {PY_TEMPLATE} from "./data/py";
import {RUST_TEMPLATE} from "./data/rust";
import {SQL_LITE_TEMPLATE} from "./data/sqlite";
import {SWIFT_TEMPLATE} from "./data/swift";
import {PHP_TEMPLATE} from "./data/php";
import {RUBY_TEMPLATE} from "./data/rb";
import {CPP_TEMPLATE} from "./data/cpp";
import {of} from "rxjs";
import {C_TEMPLATE} from "./data/c";
import {BASH_TEMPLATE} from "./data/bash";
import {GO__MULTI_TEMPLATE} from "./data/go_multi";
import {SQL_PG_TEMPLATE} from "./data/sql_postgres";

@Injectable()
export class TemplateProvider {
  httpClient = inject(HttpClient)

  templates: Template[] = [
    GO_TEMPLATE,
    TS_TEMPLATE,
    JS_TEMPLATE,
    PY_TEMPLATE,
    PHP_TEMPLATE,
    RUBY_TEMPLATE,
    CPP_TEMPLATE,
    C_TEMPLATE,
    BASH_TEMPLATE,
    KT_TEMPLATE,
    JAVA_TEMPLATE,
    RUST_TEMPLATE,
    SQL_PG_TEMPLATE,
    SQL_LITE_TEMPLATE,
    SWIFT_TEMPLATE,


    GO__MULTI_TEMPLATE,
  ]

  all() {
    return of(this.templates)
  }

  getById(id?: number) {
    return this.all()
      .pipe(
        map(data => {
          return data.find(datum => datum.id === id)
        }),
      )
  }
}
