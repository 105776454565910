import {Template} from "../type";

export const GO__MULTI_TEMPLATE: Template = {
  "id": 41,
  "type": 1,
  "single": false,
  "content": {
    "icon": "files::go",
    "name": "Golang App",
    "appTemplate": "go",
    "mainFile": "main.go",
    "files": [
      {
        "filename": "main.go",
        "filelang": "go",
        "filebody": "package main\n\nimport (\n\t\"play.ground/foo\"\n)\n\nfunc main() {\n\tfoo.Bar()\n}\n"
      },
      {
        "filelang": "text",
        "filename": "go.mod",
        "filebody": "module play.ground\n"
      },
      {
        "filelang": "go",
        "filename": "foo/foo.go",
        "filebody": "package foo\n\nimport \"fmt\"\n\nfunc Bar() {\n\tfmt.Println(\"This function lives in an another file!\")\n}\n"
      }
    ]
  }
}
