import {Template} from "../type";

export const C_TEMPLATE: Template = {
  "id": 19,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::c",
    "name": "C",
    "mainFile": "main.c",
    "files": [
      {
        "filelang": "cpp",
        "filename": "main.cpp",
        "filebody": "//cpp code"
      }
    ]
  }
}
