import {Template} from "../type";

export const JAVA_TEMPLATE: Template = {
  "id": 8,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::java",
    "name": "Java",
    "mainFile": "main.java",
    "files": [
      {
        "filelang": "java",
        "filename": "main.java",
        "filebody": "class Main {\n    public static void main(String[] args) {\n        System.out.println(\"Hello world!\");\n    }\n}"
      }
    ]
  }
}
