import {Template} from "../type";

export const KT_TEMPLATE: Template = {
  "id": 7,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::kotlin",
    "name": "Kotlin",
    "mainFile": "main.kt",
    "files": [
      {
        "filename": "main.kt",
        "filebody": "abstract class Person(val name: String) {\n    abstract fun greet()\n}\n\ninterface FoodConsumer {\n    fun eat()\n    fun pay(amount: Int) = println(\"Delicious! Here's $amount bucks!\")\n}\n\nclass RestaurantCustomer(name: String, val dish: String) : Person(name), FoodConsumer {\n    fun order() = println(\"$dish, please!\")\n    override fun eat() = println(\"*Eats $dish*\")\n    override fun greet() = println(\"It's me, $name.\")\n}\n\nfun main() {\n    val sam = RestaurantCustomer(\"Sam\", \"Mixed salad\")\n    sam.greet() // An implementation of an abstract function\n    sam.order() // A member function\n    sam.eat()   // An implementation of an interface function\n    sam.pay(10) // A default implementation in an interface\n}\n",
        "filelang": "kotlin"
      }
    ]
  }
}
