import {Template} from "../type";

export const RUST_TEMPLATE: Template = {
  "id": 11,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::rust",
    "name": "Rust",
    "mainFile": "main.rs",
    "files": [
      {
        "filelang": "rust",
        "filename": "main.rs",
        "filebody": ""
      }
    ]
  }
}
