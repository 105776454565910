import {editor} from "monaco-editor";
import IEditorOptions = editor.IEditorOptions;
import {BehaviorSubject, Subject} from "rxjs";
import {Changes} from "../../packages/utls/changedFields";
import {InjectionToken} from "@angular/core";


export const DEFAULT_SIDEBAR_WIDTH = 200
export const MIN_SIDEBAR_WIDTH = 120;
export const MAX_SIDEBAR_WIDTH = 300;

export const DEFAULT_FONT_SIZE= 15
export const DEFAULT_LANG = 'go'


export const LANGUAGES = [
  'text',
  'clojure',
  'coffee',
  'cpp',
  'csharp',
  'css',
  'dart',
  'dockerfile',
  'elixir',
  'fsharp',
  'go',
  'graphql',
  'html',
  'ini',
  'java',
  'javascript',
  'json',
  'julia',
  'kotlin',
  'less',
  'lua',
  'm3',
  'markdown',
  'mips',
  'msdax',
  'mysql',
  'objective-c',
  'pascal',
  'pascaligo',
  'perl',
  'pgsql',
  'php',
  'pla',
  'postiats',
  'powerquery',
  'powershell',
  'protobuf',
  'pug',
  'python',
  'qsharp',
  'r',
  'razor',
  'redis',
  'redshift',
  'restructuredtext',
  'ruby',
  'rust',
  'sb',
  'scala',
  'scheme',
  'scss',
  'shell',
  'solidity',
  'sophia',
  'sparql',
  'sql',
  'st',
  'swift',
  'systemverilog',
  'tcl',
  'twig',
  'typescript',
  'vb',
  'wgsl',
  'xml',
  'yaml'
];

type theme = {
  theme: string
}

export const DEFAULT_EDITOR_OPTIONS: IEditorOptions = {

  // -------------------
  // Gutter hide
  // @ts-ignore
  lineNumbers: 'on',
  // folding: false,
  // Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
  lineDecorationsWidth: 4,
  lineNumbersMinChars: 2,
  // -------------------

  // contextmenu: false,

  fontSize: 16,
  lineHeight: 1.6,
  // hover: {enabled: false},
  // renderWhitespace: 'none',
  wordWrap: 'on',
  scrollbar: {
    vertical: "hidden",
    horizontal: "hidden",
    // verticalScrollbarSize: 0,
    alwaysConsumeMouseWheel: false,
    useShadows: false,
  },


  overviewRulerBorder: false,
  overviewRulerLanes: 0,
  fontFamily: 'JetBrains Mono',
  minimap: {
    enabled: false,
    // showRegionSectionHeaders: false, // in text versions
  },
  renderLineHighlightOnlyWhenFocus: false,
  suggestOnTriggerCharacters: false,
  quickSuggestions: false,
  parameterHints: {
    enabled: false
  },
  acceptSuggestionOnEnter: "off",
  tabCompletion: "off",
  folding: false,
  foldingHighlight: false,
  selectionHighlight: false,
  selectOnLineNumbers: false,
  automaticLayout: false,
  scrollBeyondLastLine: false,
  hideCursorInOverviewRuler: true,
  renderLineHighlight: "none",
  // renderLineHighlight: "line",
  cursorWidth: 2,
  occurrencesHighlight: false,
  // occurrencesHighlight: 'off', // in next versions 'off' | 'singleFile' | 'multiFile'`

  matchBrackets: "near",

  guides: {
    indentation: false,
  },

  padding: {
    top: 0,
    bottom: 300,
  },
}

export const DEFAULT_EDITOR_OPTIONS_EMBEDDED: IEditorOptions = {
  padding: {
    bottom: 0,
  },
}

export interface EditorSettings {
  isNightTheme: boolean
  fontSize: number
  speed: number

  //--
  projectSideBarWidth: number
  //--
  collapsedRightSideBar: boolean
  //--

  playerMute: boolean

  singleFile: boolean
}

export const NotStoredSettingsKeys: (keyof EditorSettings)[] =[
  "singleFile"
]

export type StorageEditorSettings = Omit<EditorSettings, typeof NotStoredSettingsKeys[number]>

export const filterStorageEditorSettings = <T extends object, K extends keyof T>(obj: T, keysToOmit: K[]): Omit<T, K> => {
  const result = { ...obj };
  keysToOmit.forEach(key => {
    delete result[key];
  });
  return result;
}

export const DEFAULT_SETTINGS: EditorSettings = {
  fontSize: DEFAULT_FONT_SIZE,
  speed: 1,
  isNightTheme: true,
  collapsedRightSideBar: false,
  projectSideBarWidth: DEFAULT_SIDEBAR_WIDTH,
  playerMute: false,
  singleFile: true,
}

export interface SettingsProvider {
  settings$: BehaviorSubject<EditorSettings>
  settingsChanges$: Subject<Changes<EditorSettings>>

  save(settings: Partial<EditorSettings>): void
}

export const CODE_SETTINGS_PROVIDER = new InjectionToken<SettingsProvider>('CODE_SETTINGS_PROVIDER')
