import {Template} from "../type";

export const RUBY_TEMPLATE: Template = {
  "id": 12,
  "type": 0,
  "single": true,
  "content": {
    "name": "Ruby",
    "icon": "files::ruby",
    "mainFile": "main.rb",
    "files": [
      {
        "filelang": "ruby",
        "filename": "main.rb",
        "filebody": ""
      }
    ]
  }
}
