import {Template} from "../type";

export const SQL_LITE_TEMPLATE: Template = {
  "id": 17,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::sql",
    "name": "SQL (Lite)",
    "mainFile": "data.sql",
    "files": [
      {
        "filelang": "sql",
        "filename": "data.sql",
        "filebody": "CREATE TABLE users (\n  id INTEGER PRIMARY KEY AUTOINCREMENT,\n  name TEXT NOT NULL,\n  age INTEGER NOT NULL\n);\n\nINSERT INTO users (name, age) VALUES\n('Alice', 25),\n('Bob', 30),\n('Charlie', 22),\n('David', 27),\n('Eva', 29);\n\nSELECT * FROM users WHERE age > 25;"
      }
    ]
  }
}
