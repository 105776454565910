import {Route, Routes} from '@angular/router';
import {provideTranslocoScope} from '@jsverse/transloco';
import {HashMap, Translation} from '@jsverse/transloco/lib/types';

import {anonymous, authenticated, authenticatedAndActivated, authenticatedAndNotActivated} from 'auth/guard/auth.guard';
import {SUPPORTED_LANGUAGES} from 'app/i18n/types';
import {provideReplayLangScope} from 'codiew/replay/translation';
import {provideRecordLangScope} from 'codiew/record/translations';
import {provideCabinetLangScope} from 'app-pages/cabinet/translations';
import {featureFlagGuard} from "./feature/feature-flag.guard";

export const provideAppLangScope = () => {
  return provideTranslocoScope({
    scope: 'app',
    alias: 'app',
    loader: SUPPORTED_LANGUAGES.reduce((acc: HashMap<() => Promise<Translation>>, lang) => {
      acc[lang] = () => import(`./i18n/${lang}.json`);
      return acc;
    }, {})
  })
}


export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('app-pages/page.home/routes').then(m => m.HOME_ROUTES),
    providers: [
      provideAppLangScope(),
      provideRecordLangScope(),
      provideReplayLangScope(),
    ],
    canActivate: [anonymous],
  },

  {
    path: 'waitlist',
    loadComponent: () => import('app-pages/page.soon/soon.page').then(m => m.WaitlistPage), providers: [
      provideAppLangScope(),
    ],
    canActivate: [authenticatedAndNotActivated],
  },

  {
    path: 'prices',
    loadComponent: () => import('app-pages/page.price/price-page.component').then(m => m.PricePage), providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  },

  {
    path: 'about',
    loadComponent: () => import('app-pages/page.about/about.page').then(m => m.AboutPage), providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  },

  {
    path: 'features',
    loadComponent: () => import('app-pages/page.features/features.page').then(m => m.FeaturesPage), providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  },

  {
    path: 'terms',
    loadComponent: () => import('app-pages/page.terms/terms.page').then(m => m.TermsPage), providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  },

  {
    path: 'privacy',
    loadComponent: () => import('app-pages/page.privacy/privacy.page').then(m => m.PrivacyPage), providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  },


  {
    path: 'confirm',
    loadComponent: () => import(`auth/confirm/confirm.page`).then(c => c.ConfirmPage),
    providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  },
  {
    path: 'logout',
    loadComponent: () => import('auth/logout/logout.page').then(c => c.LogoutPage),
    providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  },

  {
    path: 'app',
    loadChildren: () => import('app-pages/cabinet/routes').then(m => m.CABINET_ROUTES),
    providers: [
      provideAppLangScope(),
      provideCabinetLangScope(),
    ],
    canActivate: [authenticatedAndActivated],
  },

  {
    path: 'iframe',
    loadComponent: () => import('app-pages/page.build-demo/build-demo-page.component').then(m => m.BuildDemoPage),
    canActivate: [anonymous],
    providers: [
      provideAppLangScope(),
    ],
  },

  {
    path: 'record',
    loadChildren: () => import('app-pages/page.record/routes').then(m => m.RECORD_ROUTES),
    providers: [
      provideAppLangScope(),
      provideReplayLangScope(), // TODO:: выпилить
      provideRecordLangScope(),
    ],
    canActivate: [authenticatedAndActivated],
  },

  {
    path: 'ide',
    loadComponent: () => import('app-pages/page.ide/ide-page.component').then(c => c.IdePage),
    providers: [
      provideAppLangScope(),
      provideReplayLangScope(), // TODO:: выпилить
      provideRecordLangScope(),
    ],
    canActivate: [anonymous],
  },

  {
    path: 'redirect/:provider',
    loadComponent: () => import('app-pages/redirect.auth/redirect.component').then(m => m.RedirectComponent),
    canActivate: [anonymous],
  },

  // ------------------------------------------------------------------------------------------------------------------
  // INTERACTIVE PAGES
  // ------------------------------------------------------------------------------------------------------------------

  {
    path: `exercise/:id`,
    loadComponent: () => import(`app-pages/page.exercise/exercise.page`).then(c => c.ExercisePage),
    providers: [
      provideAppLangScope(),
    ],
    canActivate: [authenticatedAndActivated],
  },

  ...[
    {
      path: 'r',
      loadChildren: () => import('app-pages/page.replay/routes').then(m => m.REPLAY_ROUTES),
      providers: [
        provideAppLangScope(),
        provideReplayLangScope(),
      ],
      data: {
        identity: "share_link"
      },
      canActivate: [anonymous],
    },
    {
      path: 'replay',
      loadChildren: () => import('app-pages/page.replay/routes').then(m => m.REPLAY_ROUTES),
      providers: [
        provideAppLangScope(),
        provideReplayLangScope(),
      ],
      canActivate: [anonymous],
    },
    {
      path: 'sql-studio',
      loadChildren: () => import('app-pages/page.replay/routes').then(m => m.REPLAY_ROUTES),
      providers: [
        provideAppLangScope(),
        provideReplayLangScope(),
      ],
      canActivate: [anonymous],
    },
  ],
  {
    path: 'studio',
    loadChildren: () => import('app-pages/page.studio/routes').then(m => m.STUDIO_ROUTES),
    providers: [
      provideAppLangScope(),
      provideReplayLangScope(),
    ],
    canMatch: [featureFlagGuard('studio')],
    canActivate: [authenticatedAndActivated],
  },

  // ------------------------------------------------------------------------------------------------------------------



  {
    path: '**',
    loadChildren: () => import('app-pages/page.not-found/routes').then(m => m.NOT_FOUND__ROUTES),
    providers: [
      provideAppLangScope(),
    ],
    canActivate: [anonymous],
  }
];
