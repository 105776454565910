import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {inject, Injectable, signal} from "@angular/core";
import {Observable, of} from "rxjs";

import {FeatureFlagKeys} from "./type";
import {FeatureFlagResponse} from "./flags";

@Injectable({providedIn: 'root'})
export class FeatureFlagController {
  private http = inject(HttpClient)

  private features = signal<Record<string, boolean>>({})

  loadFlags(): Observable<Partial<FeatureFlagResponse>> {
    return this.requestFlags()
      .pipe(
        tap((features) => this.features.set(features))
      )
  }

  supports(feature: FeatureFlagKeys): boolean {
    return this.features()[feature] ?? false
  }

  public requestFlags(): Observable<Partial<FeatureFlagResponse>> {
    const override: Partial<FeatureFlagResponse> = {
      multiFile: true, // todo:: закрыть к релизу
      longRecord: false,

      studio: true,
      templateEnabled: true,
      sqlStudio: false,
      stackblitz: false,
      excalidraw: false,

      // Табы и параметры сохранения после записи
      comments: true,
      channel: false,
      playlists: false,

      openRegistration: true, // todo:: закрыть к релизу
    }

    if (true) {
      return of(override)
    }

    // if (false) {
    //   // @ts-ignore
    //   return of({...override, channel: true, comments: true, playlists: true})
    // }

    return this.http.get<FeatureFlagResponse>('/api/flags')
      .pipe(
        tap((features) => this.features.set(features))
      )
  }
}
