import {Template} from "../type";

export const GO_TEMPLATE: Template = {
  "id": 9,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::go",
    "name": "Golang",
    "mainFile": "main.go",
    "files": [
      {
        "filelang": "go",
        "filename": "main.go",
        "filebody": "package main\n\nimport (\n    \"fmt\"\n    \"flag\"\n)\n\nfunc main() {\n    // Определение флагов\n\tname := flag.String(\"name\", \"defaultName\", \"your name\")\n\tage := flag.Int(\"age\", 18, \"your age\")\n\tverbose := flag.Bool(\"v\", false, \"enable verbose mode\")\n\n\t// Разбор флагов\n\tflag.Parse()\n\n\t// Использование значений флагов\n\tfmt.Println(\"Name:\", *name)\n\tfmt.Println(\"Age:\", *age)\n\tfmt.Println(\"Verbose:\", *verbose)\n}\n"
      }
    ]
  }
}
