import {Template} from "../type";

export const SWIFT_TEMPLATE: Template = {
  "id": 13,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::swiftlang",
    "name": "Swift",
    "mainFile": "main.swift",
    "files": [
      {
        "filelang": "swift",
        "filename": "main.swift",
        "filebody": ""
      }
    ]
  }
}
