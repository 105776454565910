import {Template} from "../type";

export const TS_TEMPLATE: Template = {
  id: 3,
  type: 0,
  single: true,
  content: {
    "icon": "files::typescript",
    "name": "TypeScript",
    "mainFile": "index.ts",
    "files": [
      {
        "filelang": "typescript",
        "filename": "index.ts",
        "filebody": "// Hello World Program\n\nconsole.log(\"Hello,World!\")"
      }
    ]
  }
}
