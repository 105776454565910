import {Template} from "../type";

export const BASH_TEMPLATE: Template = {
  "id": 20,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::shell",
    "name": "Bash",
    "mainFile": "script.sh",
    "files": [
      {
        "filelang": "bash",
        "filename": "script.sh",
        "filebody": ""
      }
    ]
  }
}
