import {Template} from "../type";

export const CPP_TEMPLATE: Template = {
  "id": 18,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::cpp",
    "name": "C++",
    "mainFile": "main.cpp",
    "files": [
      {
        "filelang": "cpp",
        "filename": "main.cpp",
        "filebody": "//cpp code"
      }
    ]
  }
}
