import {Template} from "../type";

export const SQL_PG_TEMPLATE: Template = {
  "id": 21,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::sql",
    "name": "PostgresQL",
    "mainFile": "data.sql",
    "files": [
      {
        "filelang": "sql",
        "filename": "data.sql",
        "filebody": "CREATE TABLE categories\n" +
          "(\n" +
          "    id   SERIAL PRIMARY KEY,\n" +
          "    name TEXT NOT NULL\n" +
          ");\n" +
          "\n" +
          "CREATE TABLE products\n" +
          "(\n" +
          "    id          SERIAL PRIMARY KEY,\n" +
          "    price       NUMERIC(10, 2) NOT NULL,\n" +
          "    category_id INT            NOT NULL REFERENCES categories (id) ON DELETE CASCADE\n" +
          ");\n" +
          "\n" +
          "INSERT INTO categories (name)\n" +
          "VALUES ('Electronics'),\n" +
          "       ('Clothing'),\n" +
          "       ('Books'),\n" +
          "       ('Home & Kitchen'),\n" +
          "       ('Toys'),\n" +
          "       ('Beauty'),\n" +
          "       ('Sports'),\n" +
          "       ('Automotive'),\n" +
          "       ('Jewelry'),\n" +
          "       ('Garden');\n" +
          "\n" +
          "INSERT INTO products (price, category_id)\n" +
          "VALUES (199.99, 1),\n" +
          "       (99.99, 1),\n" +
          "       (49.99, 1),  -- Electronics\n" +
          "       (29.99, 2),\n" +
          "       (19.99, 2),\n" +
          "       (39.99, 2),  -- Clothing\n" +
          "       (10.99, 3),\n" +
          "       (15.99, 3),  -- Books\n" +
          "       (99.99, 4),\n" +
          "       (79.99, 4),\n" +
          "       (69.99, 4),  -- Home & Kitchen\n" +
          "       (24.99, 5),\n" +
          "       (34.99, 5),  -- Toys\n" +
          "       (9.99, 6),\n" +
          "       (14.99, 6),  -- Beauty\n" +
          "       (59.99, 7),\n" +
          "       (89.99, 7),  -- Sports\n" +
          "       (199.99, 8),\n" +
          "       (179.99, 8), -- Automotive\n" +
          "       (499.99, 9),\n" +
          "       (799.99, 9), -- Jewelry\n" +
          "       (49.99, 10),\n" +
          "       (69.99, 10); -- Garden\n" +
          "\n" +
          "SELECT c.id, c.name, SUM(p.price) AS total_price\n" +
          "FROM categories c\n" +
          "         JOIN products p ON c.id = p.category_id\n" +
          "GROUP BY c.id, c.name\n" +
          "ORDER BY total_price DESC\n" +
          "LIMIT 1;\n" +
          "\n" +
          "SELECT c.id, c.name, SUM(p.price) AS total_price\n" +
          "FROM categories c\n" +
          "         JOIN products p ON c.id = p.category_id\n" +
          "GROUP BY c.id, c.name\n" +
          "ORDER BY total_price DESC\n" +
          "LIMIT 1;\n"
      }
    ]
  }
}
