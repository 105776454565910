import {Template} from "../type";

export const JS_TEMPLATE: Template = {
  "id": 2,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::javascript",
    "name": "JavaScript",
    "mainFile": "index.js",
    "files": [
      {
        "filelang": "javascript",
        "filename": "index.js",
        "filebody": "// Hello World Program\n\nconsole.log(\"Hello,World!\")"
      }
    ]
  }
}
