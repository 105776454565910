import {Template} from "../type";

export const PHP_TEMPLATE: Template = {
  "id": 10,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::php",
    "name": "PHP",
    "mainFile": "index.php",
    "files": [
      {
        "filelang": "php",
        "filename": "index.php",
        "filebody": "<?php \n\necho 'Hello, World!';"
      }
    ]
  }
}
