import {Template} from "../type";

export const PY_TEMPLATE: Template = {
  "id": 6,
  "type": 0,
  "single": true,
  "content": {
    "icon": "files::python",
    "name": "Python",
    "mainFile": "main.py",
    "files": [
      {
        "filelang": "python",
        "filename": "main.py",
        "filebody": "# Hello World Program\n\nprint(\"Hello, World!\")"
      }
    ]
  }
}
